<template>
  <div class="activity_create">
    <div class="activity_create_back" @click="handleBack">
      <el-button type="text">
        <i class="el-icon-arrow-left"></i>返回活动列表</el-button
      >
    </div>
    <div class="activity_create_title">
      {{ this.$route.query.type == "create" ? "发布活动" : "编辑活动" }}
    </div>
    <el-form
      :model="ruleForm"
      :rules="rules"
      ref="ruleForm"
      class="demo-ruleForm"
      label-position="right"
      label-width="130px"
    >
      <div class="activity_create_bg">
        <h4>活动城市</h4>
        <el-form-item label="选择活动城市 " prop="citySelect">
          <el-select
            v-model="ruleForm.citySelect"
            filterable
            clearable
            placeholder="请选择活动城市"
            style="margin-left: 0; width: 98%"
            @change="handleChangeSelect($event, 'city')"
          >
            <el-option
              v-for="item in optionsCity"
              :key="item.id"
              :label="item.country + '-' + item.city"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="选择位置">
          <div style="display: flex">
            <el-button @click="handlePosition">选择位置</el-button>

            <el-dialog
              title="选择位置"
              :visible.sync="mapVisible"
              :fullscreen="false"
              id="mapDialog"
              :close-on-click-modal="false"
            >
              <div style="margin-bottom: 10px">
                地区：<el-input
                  v-model="location2"
                  class="lineinput"
                  style="width: 200px"
                  size="mini"
                />
                关键词：<el-input
                  v-model="keyword"
                  class="lineinput"
                  style="width: 200px"
                  size="mini"
                />
              </div>
              <baidu-map
                class="map"
                :center="center"
                :zoom="zoom"
                @ready="handler"
                :scroll-wheel-zoom="true"
                @click="clickEvent"
              >
                <bm-navigation anchor="BMAP_ANCHOR_TOP_RIGHT"></bm-navigation>
                <bm-city-list anchor="BMAP_ANCHOR_TOP_LEFT"></bm-city-list>
                <bm-geolocation
                  anchor="BMAP_ANCHOR_BOTTOM_RIGHT"
                  :showAddressBar="true"
                  :autoLocation="true"
                  @locationSuccess="getLoctionSuccess"
                ></bm-geolocation>
                <bm-view
                  :style="{
                    width: '100%',
                    height: '300px',
                    flex: 1,
                    marginTop: '0px',
                  }"
                ></bm-view>
                <bm-local-search
                  :keyword="keyword"
                  :auto-viewport="false"
                  :location="location2"
                  style="height: 150px; overflow-y: scroll; margin: 2px 0"
                ></bm-local-search>
              </baidu-map>
              <div class="demo-input-suffix" style="margin-top: 2vh">
                <el-link type="info">经度：</el-link
                ><el-input
                  class="lineinput"
                  style="width: 200px"
                  size="mini"
                  v-model.number="locData.longitude"
                  :disabled="true"
                ></el-input>
                <el-link type="info"> 纬度：</el-link
                ><el-input
                  class="lineinput"
                  style="width: 200px"
                  size="mini"
                  v-model.number="locData.latitude"
                  :disabled="true"
                ></el-input>
                <el-link type="info"> 位置：</el-link
                ><el-input
                  class="lineinput"
                  style="width: 200px"
                  size="mini"
                  v-model="locData.address"
                  :disabled="true"
                ></el-input>
              </div>
              <div
                slot="footer"
                class="dialog-footer"
                style="margin-top: -30px"
              >
                <el-button size="small" @click.native="mapVisible = false"
                  >取消</el-button
                >
                <el-button
                  type="primary"
                  size="small"
                  @click.native="findlocation"
                  >确定</el-button
                >
              </div>
            </el-dialog>

            <el-button @click="handlePositionInput" style="margin-left: 10px"
              >手动输入经纬度</el-button
            >

            <el-dialog
              title="输入经纬度"
              :visible.sync="mapInputVisible"
              :fullscreen="false"
              :close-on-click-modal="false"
            >
              <el-input
                placeholder="请输入经度"
                v-model="input.lng"
                style="margin-bottom: 10px"
              ></el-input>
              <el-input placeholder="请输入纬度" v-model="input.lat"></el-input>

              <div slot="footer" class="dialog-footer">
                <el-button size="small" @click.native="mapInputVisible = false"
                  >取消</el-button
                >
                <el-button
                  type="primary"
                  size="small"
                  @click.native="findInputlocation"
                  >确定</el-button
                >
              </div>
            </el-dialog>
          </div>
          <div v-if="locData.longitude && locData.latitude">
            经度：{{ this.locData.longitude }} 纬度：{{ this.locData.latitude }}
          </div>
        </el-form-item>
      </div>
      <div class="activity_create_bg">
        <h4>基础信息</h4>
        <el-form-item label="活动名称(中文)" prop="activityTitle">
          <el-input
            v-model="ruleForm.activityTitle"
            maxlength="60"
            show-word-limit
            placeholder="请输入活动名称(中文)"
          ></el-input>
        </el-form-item>
        <el-form-item label="活动名称(英文)" prop="activityTitleUs">
          <el-input
            v-model="ruleForm.activityTitleUs"
            maxlength="60"
            show-word-limit
            placeholder="请输入活动名称(英文)"
          ></el-input>
        </el-form-item>
        <el-form-item label="活动简介(中文)" prop="activitySubtitle">
          <el-input
            v-model="ruleForm.activitySubtitle"
            maxlength="200"
            show-word-limit
            type="textarea"
            placeholder="请输入活动简介(中文)"
          ></el-input>
        </el-form-item>
        <el-form-item label="活动简介(英文)" prop="activitySubtitleUs">
          <el-input
            v-model="ruleForm.activitySubtitleUs"
            maxlength="200"
            show-word-limit
            type="textarea"
            placeholder="请输入活动简介(英文)"
          ></el-input>
        </el-form-item>
        <el-form-item label="活动时间" prop="date">
          <el-date-picker
            v-model="ruleForm.date"
            type="daterange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            value-format="yyyy-MM-dd"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label="活动位置(中文)" prop="activityLocation">
          <el-input
            v-model="ruleForm.activityLocation"
            placeholder="请输入活动位置(中文)"
          ></el-input>
        </el-form-item>
        <el-form-item label="活动位置(英文)" prop="activityLocationUs">
          <el-input
            v-model="ruleForm.activityLocationUs"
            placeholder="请输入活动位置(英文)"
          ></el-input>
        </el-form-item>

        <el-form-item label="报名价格" prop="activityPrice">
          <el-input-number
            v-model="ruleForm.activityPrice"
            :precision="2"
            :step="0.1"
          ></el-input-number>
        </el-form-item>
        <el-form-item label="报名数量" prop="activityStock">
          <el-input-number v-model="ruleForm.activityStock"></el-input-number>
        </el-form-item>
        <el-form-item label="报名时间" prop="enlistDate">
          <el-date-picker
            v-model="ruleForm.enlistDate"
            type="daterange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            value-format="yyyy-MM-dd"
          >
          </el-date-picker>
        </el-form-item>
      </div>
      <div class="activity_create_bg">
        <h4>报名要求</h4>

        <el-form-item label="报名级别" prop="activityGrade">
          <el-radio v-model="ruleForm.activityGrade" :label="1"
            >行世界等级A</el-radio
          >
          <el-radio v-model="ruleForm.activityGrade" :label="2"
            >行世界等级B</el-radio
          >
          <el-radio v-model="ruleForm.activityGrade" :label="3"
            >行世界等级C</el-radio
          >
          <el-radio v-model="ruleForm.activityGrade" :label="4"
            >行世界等级D</el-radio
          >
          <el-radio v-model="ruleForm.activityGrade" :label="0"
            >无限制</el-radio
          >
        </el-form-item>
      </div>

      <div class="activity_create_bg">
        <h4>图文描述</h4>

        <el-form-item label="主图图片 " prop="img">
          <p style="color: #000000; opacity: 0.5">
            上传1:1的图片,大小不能超过 10MB（默认第一张图片为主图）
          </p>

          <div class="video_upload">
            <el-upload
              :action="UploadUrl()"
              ref="uploadimg"
              :limit="1"
              :auto-upload="false"
              :on-change="imageChange"
              :show-file-list="true"
              :file-list="[]"
              list-type="picture-card"
              :on-exceed="handleExceed"
              :class="{ disabled: uploadDisabled }"
            >
              <i class="el-icon-plus"></i>
            </el-upload>

            <el-progress
              v-show="progressFlagImg"
              :percentage="loadProgressImg"
            ></el-progress>

            <!-- 拖拽组件 -->
            <draggable
              v-model="zlist"
              :animation="100"
              class="dira"
              @update="handleDraggable"
            >
              <div class="isblocks" v-for="(item, index) in zlist" :key="index">
                <div class="ress">
                  <el-image
                    ref="preview"
                    :style="{
                      width: upWith + 'px',
                      height: upHeight + 'px',
                      borderRadius: upBradius + 'px',
                    }"
                    :preview-src-list="zlist"
                    :src="item"
                  ></el-image>
                  <div class="imgs_prews">
                    <div
                      class="imgs_prew"
                      :style="{
                        width: upWith + 'px',
                        height: upHeight + 'px',
                        borderRadius: upBradius + 'px',
                      }"
                    >
                      <i @click="ylimg(index)" class="el-icon-view"></i>
                      <span></span>
                      <i @click="deleteimg(index)" class="el-icon-delete"></i>
                    </div>
                  </div>
                </div>
              </div>
            </draggable>
          </div>
        </el-form-item>

        <el-form-item label="上传视频 " prop="video">
          <p style="color: #000000; opacity: 0.5; margin-left: 0">
            大小不能超过 600MB
          </p>

          <div class="video_upload">
            <el-upload
              ref="video"
              list-type="picture-card"
              v-model="ruleForm.videoPath"
              :action="UploadUrl()"
              :show-file-list="false"
              :on-change="imageChangeVideo"
              :on-progress="uploadVideoProcess"
              :http-request="UploadVideo"
            >
              <i class="el-icon-upload"></i>
            </el-upload>

            <div class="list-vedio">
              <div v-for="(item, index) in fileListVideo" :key="index">
                <video
                  v-if="ruleForm.videoPath != '' && videoFlag == false"
                  :src="item"
                  class="avatar"
                  controls="controls"
                >
                  您的浏览器不支持视频播放
                </video>
                <el-button type="danger" @click="handleRemoveVideo(index)"
                  >删除该视频</el-button
                >
              </div>
            </div>

            <el-progress
              v-show="progressFlag"
              :percentage="loadProgress"
            ></el-progress>
          </div>
        </el-form-item>

        <el-form-item
          label="详情描述(中) "
          prop="activityLinkEdit"
          class="rich_z-index"
        >
          <div id="richText"></div>

          <div class="el-form_btn_b_save">
            <el-button @click="handleSaveShelves('zh')">保存草稿</el-button>
            <div class="el-form_btn_b_save_div" v-if="isSave">
              <el-button
                type="success"
                icon="el-icon-check"
                size="mini"
                circle
              ></el-button
              >最后保存于{{ getTimeSave }}
            </div>
          </div>
        </el-form-item>

        <el-form-item
          label="详情描述(英) "
          prop="activityLinkEditUs"
          class="rich_z-index"
        >
          <div id="richText2"></div>

          <div class="el-form_btn_b_save">
            <el-button @click="handleSaveShelves('us')">保存草稿</el-button>
            <div class="el-form_btn_b_save_div" v-if="isSaveUs">
              <el-button
                type="success"
                icon="el-icon-check"
                size="mini"
                circle
              ></el-button
              >最后保存于{{ getTimeSaveUs }}
            </div>
          </div>
        </el-form-item>
      </div>

      <div class="activity_create_bg">
        <h4>是否上架</h4>

        <el-form-item label="是否上架" prop="isVisible">
          <el-radio-group v-model="ruleForm.isVisible">
            <el-radio :label="true">是</el-radio>
            <el-radio :label="false">否</el-radio>
          </el-radio-group>
        </el-form-item>
      </div>

      <div class="activity_create_bg">
        <h4>服务政策</h4>

        <el-form-item label="退定政策" prop="policy">
          <el-radio-group v-model="ruleForm2.policy">
            <el-radio :label="0">不可退订</el-radio>
            <el-radio label="ok">可退订</el-radio>
          </el-radio-group>

          <el-select
            v-model="ruleForm2.policyOk"
            placeholder="请选择"
            v-if="ruleForm2.policy == 'ok'"
            @change="handleChangeSelect($event, 'cancel')"
          >
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
      </div>

      <div class="el_form_item_footer">
        <el-form-item>
          <el-button type="primary" @click="submitForm('ruleForm')"
            >提交</el-button
          >

          <el-button @click="resetForm('ruleForm')">重置</el-button>
        </el-form-item>
      </div>
    </el-form>
  </div>
</template>

<script>
import axios from "axios";

import { reqUrl } from "@/api/apiUrl";
import { request } from "@/api/request";
import { hideLoading, showLoading } from "@/util/loading";

import Breadcrumb from "@/components/BreadCrumb";
import E from "wangeditor"; //引入富文本插件

import draggable from "vuedraggable";

let editor;
let editor2;

export default {
  props: {
    //宽度
    upWith: {
      type: Number,
      default: 100,
    },
    //高度
    upHeight: {
      type: Number,
      default: 100,
    },
    //圆角
    upBradius: {
      type: Number,
      default: 0,
    },
  },
  inject: ["reload"],
  components: {
    Breadcrumb,
    draggable,
  },

  data() {
    return {
      BreadcrumbCon: [
        {
          name: "行世界",
        },
        {
          name: "活动管理",
        },
        {
          name: "发布活动",
        },
      ], // 面包屑数据

      ruleForm: {
        citySelect: "",
        activityTitle: "",
        activityTitleUs: "",
        activitySubtitle: "",
        activitySubtitleUs: "",
        date: [],
        activityLocation: "",
        activityLocationUs: "",
        activityPrice: "",
        activityStock: "",
        activityGrade: 1,
        primaryImage: "",
        isVisible: true,
        enlistDate: [],
        videoPath: "",
      },

      ruleForm2: {
        policy: 0,
        policyOk: 1,
      },

      location2: "",
      keyword: "",
      center: { lng: 111.752912, lat: 40.832246 },
      zoom: 12,
      mapVisible: false,
      locData: {
        longitude: "",
        latitude: "",
        address: "",
      },
      temp: {
        location: "",
        lng: "",
        lat: "",
      },

      mapInputVisible: false,
      input: {
        lng: "",
        lat: "",
      },

      options: [
        {
          value: 1,
          label: "提前1天退订",
        },
        {
          value: 2,
          label: "提前3天退订",
        },
        {
          value: 3,
          label: "提前7天退订",
        },
        {
          value: 4,
          label: "提前14天退订",
        },
      ],
      optionsCity: [],

      rules: {
        citySelect: [
          { required: true, message: "请选择活动城市", trigger: "blur" },
        ],
        activityTitle: [
          { required: true, message: "请输入活动名称", trigger: "blur" },
          { min: 0, max: 60, message: "已超出字数限制", trigger: "blur" },
        ],
        activitySubtitle: [
          { required: true, message: "请输入活动简介", trigger: "blur" },
          { min: 0, max: 200, message: "已超出字数限制", trigger: "blur" },
        ],
        activityLocation: [
          { required: true, message: "请输入活动位置", trigger: "blur" },
        ],
        activityPrice: [
          { required: true, message: "请输入活动价格", trigger: "blur" },
        ],
      },
      fileList: [],
      fileListImg: [],
      uploadDisabled: false,
      progressFlagImg: false,
      loadProgressImg: 0,
      deleteList: [],
      dataCopyInfo: {},
      dataInxInfo: 0,
      zlist: [], //组件数组

      // 视频相关
      fileListVideo: [],
      videoFlag: false,
      // //是否显示进度条
      videoUploadPercent: 0,
      progressFlag: false,
      loadProgress: 0,

      worldActivityEdit: {},

      isSave: false,
      isSaveUs: false,
      getTimeSave: "",
      getTimeSaveUs: "",
    };
  },

  watch: {
    fileListImg(newvalue) {
      this.fileListImg = newvalue;
    },
  },

  created() {
    //回显数组
    this.zlist = this.fileListImg;
  },

  mounted() {
    setTimeout(() => {
      // 中文
      editor = new E("#richText");
      editor.config.uploadImgServer = reqUrl.richImageUpload;
      editor.config.uploadImgMaxSize = 20 * 1024 * 1024;
      editor.config.uploadFileName = "file";
      editor.create();
      if (this.$route.query.type == "edit") {
        editor.txt.html(this.worldActivityEdit.activityLinkEdit);
      } else {
        editor.txt.html();
      }
      // 英文
      editor2 = new E("#richText2");
      editor2.config.uploadImgServer = reqUrl.richImageUpload;
      editor2.config.uploadFileName = "file";
      editor2.create();
      if (this.$route.query.type == "edit") {
        editor2.txt.html(this.worldActivityEdit.activityLinkEdit);
      } else {
        editor2.txt.html();
      }
    }, 100);

    const opt = {
      url: reqUrl.worldTravelSelectCity,
      method: "POST",
      params: JSON.stringify({
        city: "",
        country: "",
      }),
      resFunc: (res) => {
        console.log(res.data);
        if (res.data.code != 0) {
          this.$notify.error({
            title: "错误",
            message: res.data.message,
          });
        } else if (res.data.code == 0) {
          this.optionsCity = res.data.data.list;
        }
      },
      errFunc: (err) => {
        this.$notify.error({
          title: "错误",
          message: err,
        });
      },
    };
    request(opt);

    if (this.$route.query.type == "edit") {
      this.worldActivityEdit = JSON.parse(
        localStorage.getItem("worldActivityEdit")
      );

      if (this.worldActivityEdit) {
        let videos = this.worldActivityEdit.videos.split(",");
        this.fileListVideo = videos;
        this.ruleForm = this.worldActivityEdit;
        let listDate = [
          this.worldActivityEdit.startTime,
          this.worldActivityEdit.endTime,
        ];
        this.$set(this.worldActivityEdit, "date", listDate);
        this.fileList.push({ url: this.ruleForm.primaryImage });
        this.fileListImg.push(this.ruleForm.primaryImage);

        if (this.worldActivityEdit.cancelRule != 0) {
          var obj = {
            policy: "ok",
            policyOk: this.worldActivityEdit.cancelRule,
          };
          this.ruleForm2 = obj;
          this.$forceUpdate();
        } else {
          var obj = { policy: this.worldActivityEdit.cancelRule };
          this.ruleForm2 = obj;
          this.$forceUpdate();
        }

        this.ruleForm.citySelect = this.worldActivityEdit.cityId;
        this.locData.longitude = this.worldActivityEdit.activityLocationLng;
        this.locData.latitude = this.worldActivityEdit.activityLocationLat;

        let listDateEnlist = [
          this.worldActivityEdit.startEnlist,
          this.worldActivityEdit.endEnlist,
        ];
        this.$set(this.worldActivityEdit, "enlistDate", listDateEnlist);
      }
    }
  },

  methods: {
    handleBack() {
      this.$router.push("/worldActivityList");
    },

    UploadUrl() {
      return reqUrl.imageUpload;
    },

    //选择图片后做图片格式限制（手动上传图片时，before-upload钩子无效，使用此方法替代）
    imageChange(file, fileList) {
      this.progressFlagImg = true;
      this.loadProgressImg = 0;
      const interval = setInterval(() => {
        if (this.loadProgressImg >= 99) {
          clearInterval(interval);
          return;
        }
        this.loadProgressImg += 1;
      }, 20);

      if (fileList.length >= 1) {
        this.uploadDisabled = true;
      }
      axios.get(file.url, { responseType: "blob" }).then((res) => {
        const formData = new FormData();
        formData.append("file", res.data);
        formData.append("userId", 1);
        formData.append("scene", 2);

        axios.defaults.headers.post["Authorization"] = localStorage.getItem(
          "resHeadersAuthorization"
        );
        axios({
          method: "POST",
          url: reqUrl.imageUpload,
          header: {
            "Content-Type": "multipart/form-data",
          },
          data: formData,
        }).then((res) => {
          // this.fileListImg.push(res.data.data.url);
          this.zlist.push(res.data.data.url);

          this.progressFlagImg = false;
          this.loadProgress = 100;
        });
      });

      const isImage =
        file.raw.type == "image/png" ||
        file.raw.type == "image/jpg" ||
        file.raw.type == "image/jpeg";
      const isLt5M = file.size < 1024 * 1024 * 10;
      if (!isImage) {
        this.$message.error("上传只能是png,jpg,jpeg格式!");
      }
      if (!isLt5M) {
        this.$message.error("上传图片大小不能超过 10MB!");
      }

      if (isImage && isLt5M) {
        this.uploadFile = file.raw || null;
      } else {
        fileList.splice(-1, 1);
      }
    },

    //超出限制个数钩子
    handleExceed(files) {
      this.$message.warning("当前限制选择1个文件，请删除图片后重新添加");
    },

    handleDraggable(event) {
      const { oldIndex, newIndex } = event;

      console.log(this.fileListImg, "this.fileListImg");

      this.fileListImg.splice(
        newIndex,
        0,
        this.fileListImg.splice(oldIndex, 1)[0]
      );
    },

    //删除
    deleteimg(index) {
      this.$delete(this.zlist, index);

      if (this.fileListImg.length < 1) {
        this.uploadDisabled = false;
      }
    },
    //预览
    ylimg(index) {
      this.$refs.preview[index].showViewer = true;
    },

    //视频上传
    UploadVideo(params) {
      this.ruleForm.videoPath = URL.createObjectURL(params.file);
      this.progressFlag = true;
      this.loadProgress = 0;
      const interval = setInterval(() => {
        if (this.loadProgress >= 99) {
          clearInterval(interval);
          return;
        }
        this.loadProgress += 1;
      }, 20);
    },

    imageChangeVideo(file, fileList) {
      axios.get(file.url, { responseType: "blob" }).then((res) => {
        const formData = new FormData();
        formData.append("file", res.data);
        formData.append("userId", 1);
        formData.append("scene", 2);

        axios.defaults.headers.post["Authorization"] = localStorage.getItem(
          "resHeadersAuthorization"
        );
        axios({
          method: "POST",
          url: reqUrl.imageUpload,
          header: {
            "Content-Type": "multipart/form-data",
          },
          data: formData,
        }).then((res) => {
          this.fileListVideo.push(res.data.data.url);
          this.loadProgress = 100;
        });
      });

      const isLt5M = file.size < 1024 * 1024 * 600;

      if (!isLt5M) {
        this.$message.error("上传图片大小不能超过 600MB!");
      }

      if (isLt5M) {
        this.uploadFile = file.raw || null;
      } else {
        fileList.splice(-1, 1);
      }
    },

    // 删除视频
    handleRemoveVideo(index) {
      this.fileListVideo.splice(index, 1);
    },

    uploadVideoProcess(event, file, fileList) {
      this.videoFlag = true;
      this.videoUploadPercent = file.percentage.toFixed(0) * 1;
    },

    handleSaveShelves(type) {
      if (type == "zh") {
        const opt = {
          url: reqUrl.goodsStringToFileUploadUrl,
          method: "POST",
          params: JSON.stringify({
            fileString: editor.txt.html(),
            title: this.ruleForm.activityTitle,
          }),
          resFunc: (res) => {
            console.log(res.data);
            if (res.data.code != 0) {
              this.$notify.error({
                title: "错误",
                message: res.data.message,
              });
            } else if (res.data.code == 0) {
              this.$message({
                message: "保存成功",
                type: "success",
              });
              this.isSave = true;
              this.getTimeSave = new Date().toLocaleString();

              localStorage.setItem("htmlUrlCrearteZh", res.data.data.url);
            }
          },
          errFunc: (err) => {
            this.$notify.error({
              title: "错误",
              message: err,
            });
            console.log(err, "err1");
          },
        };
        request(opt);
      } else if (type == "us") {
        const opt = {
          url: reqUrl.goodsStringToFileUploadUrl,
          method: "POST",
          params: JSON.stringify({
            fileString: editor2.txt.html(),
            title: this.ruleForm.activityTitleUs,
          }),
          resFunc: (res) => {
            console.log(res.data);
            if (res.data.code != 0) {
              this.$notify.error({
                title: "错误",
                message: res.data.message,
              });
            } else if (res.data.code == 0) {
              this.$message({
                message: "保存成功",
                type: "success",
              });
              this.isSaveUs = true;
              this.getTimeSaveUs = new Date().toLocaleString();

              localStorage.setItem("htmlUrlCrearteUs", res.data.data.url);
            }
          },
          errFunc: (err) => {
            this.$notify.error({
              title: "错误",
              message: err,
            });
            console.log(err, "err1");
          },
        };
        request(opt);
      }
    },

    handleChangeSelect(e, type) {
      if (type == "city") {
        this.$set(this.ruleForm, "citySelect", e);
      } else if (type == "cancel") {
        this.$set(this.ruleForm2, "policyOk", e);
      }
      this.$forceUpdate();
    },

    handlePosition() {
      this.mapVisible = true;
    },

    handlePositionInput() {
      this.mapInputVisible = true;
    },

    handler({ BMap, map }) {
      let _this = this; // 设置一个临时变量指向vue实例，因为在百度地图回调里使用this，指向的不是vue实例；
      let geolocation = new BMap.Geolocation();
      geolocation.getCurrentPosition(
        function (r) {
          console.log(r);
          _this.center = { lng: r.longitude, lat: r.latitude }; // 设置center属性值
          _this.autoLocationPoint = { lng: r.longitude, lat: r.latitude }; // 自定义覆盖物
          _this.initLocation = true;
        },
        { enableHighAccuracy: true }
      );
      window.map = map;
    },

    //点击地图监听
    clickEvent(e) {
      map.clearOverlays();
      let Icon_0 = new BMap.Icon(
        "http://api0.map.bdimg.com/images/marker_red_sprite.png",
        new BMap.Size(64, 64),
        { anchor: new BMap.Size(18, 32), imageSize: new BMap.Size(36, 25) }
      );
      let myMarker = new BMap.Marker(new BMap.Point(e.point.lng, e.point.lat), {
        icon: Icon_0,
      });
      map.addOverlay(myMarker);
      //用所定位的经纬度查找所在地省市街道等信息
      let point = new BMap.Point(e.point.lng, e.point.lat);
      let gc = new BMap.Geocoder();
      let _this = this;
      gc.getLocation(point, function (rs) {
        let addComp = rs.addressComponents;
        _this.locData.address = rs.address;
      });
      this.locData.longitude = e.point.lng;
      this.locData.latitude = e.point.lat;
    },

    //定位成功回调
    getLoctionSuccess(point, AddressComponent, marker) {
      map.clearOverlays();
      let Icon_0 = new BMap.Icon(
        "http://api0.map.bdimg.com/images/marker_red_sprite.png",
        new BMap.Size(64, 64),
        { anchor: new BMap.Size(18, 32), imageSize: new BMap.Size(36, 36) }
      );
      let myMarker = new BMap.Marker(
        new BMap.Point(point.point.lng, point.point.lat),
        { icon: Icon_0 }
      );
      map.addOverlay(myMarker);
      this.locData.longitude = point.point.lng;
      this.locData.latitude = point.point.lat;
    },

    //返回选中点的位置
    findlocation() {
      this.temp.location = this.keyword;
      this.temp.lng = this.locData.longitude;
      this.temp.lat = this.locData.latitude;
      this.mapVisible = false;
    },

    // 手动输入-返回经纬度位置
    findInputlocation() {
      this.locData.longitude = this.input.lng;
      this.locData.latitude = this.input.lat;
      this.mapInputVisible = false;
    },

    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (this.locData.longitude !== "" && this.locData.latitude !== "") {
            this.$confirm("请问您是否确认提交信息？", "提示", {
              confirmButtonText: "确定",
              cancelButtonText: "取消",
              type: "warning",
            })
              .then(() => {
                showLoading();
                const opt = {
                  url:
                    this.$route.query.type == "create"
                      ? reqUrl.worldActivityInfoCreate
                      : reqUrl.worldActivityInfoEdit,
                  method: "POST",
                  params: JSON.stringify({
                    id:
                      this.$route.query.type == "edit"
                        ? this.worldActivityEdit.id
                        : null,
                    cityId: this.ruleForm.citySelect, // 城市id
                    activityTitle: this.ruleForm.activityTitle, // 活动名称中
                    activityTitleUs: this.ruleForm.activityTitleUs, // 活动名称英
                    activityPrice: this.ruleForm.activityPrice, // 活动价格
                    activityStock: this.ruleForm.activityStock, // 活动库存
                    activityGrade: this.ruleForm.activityGrade, // 参与活动所需等级
                    isVisible: this.ruleForm.isVisible, // 是否上架
                    cancelRule:
                      this.ruleForm2.policy == 0
                        ? this.ruleForm2.policy
                        : this.ruleForm2.policyOk, // 退订政策
                    primaryImage: this.zlist[0], // 主页图片
                    startTime: this.ruleForm.date[0], // 开始时间
                    endTime: this.ruleForm.date[1], // 结束时间
                    activitySubtitle: this.ruleForm.activitySubtitle, // 活动描述中
                    activitySubtitleUs: this.ruleForm.activitySubtitleUs, // 活动描述英
                    activityLocation: this.ruleForm.activityLocation, // 活动地址zhong
                    activityLocationUs: this.ruleForm.activityLocationUs, // 活动地址ying
                    activityLink: localStorage.getItem("htmlUrlCrearteZh"),
                    activityLinkUs: localStorage.getItem("htmlUrlCrearteUs"),
                    activityLinkEdit: editor.txt.html(),
                    activityLinkUsEdit: editor2.txt.html(),
                    activityLocationLng: this.locData.longitude,
                    activityLocationLat: this.locData.latitude,
                    startEnlist: this.ruleForm.enlistDate[0],
                    endEnlist: this.ruleForm.enlistDate[1],
                    videos: this.fileListVideo.join(","), // 视频
                  }),
                  resFunc: (res) => {
                    console.log(res.data);
                    if (res.data.code != 0) {
                      this.$notify.error({
                        title: "错误",
                        message: res.data.message,
                      });
                      hideLoading();
                    } else if (res.data.code == 0) {
                      if (this.$route.query.type == "create") {
                        this.$message({
                          message: "活动发布成功",
                          type: "success",
                        });
                      } else {
                        this.$message({
                          message: "活动编辑成功",
                          type: "success",
                        });
                      }
                      this.$router.push("/worldActivityList");
                      hideLoading();
                    }
                    hideLoading();
                  },
                  errFunc: (err) => {
                    this.$notify.error({
                      title: "错误",
                      message: err,
                    });
                    hideLoading();
                    console.log(err, "err1");
                  },
                };
                request(opt);
              })
              .catch((err) => {
                console.log(err, "err");
                this.$message({
                  type: "info",
                  message: "已取消",
                });
              });
          } else {
            this.$message.error("请检查是否填写经纬度");
          }
        } else {
          this.$message.error("请检查填写信息是否有误");
          return false;
        }
      });
    },

    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
  },

  destroyed() {
    localStorage.removeItem("worldActivityEdit");
    localStorage.removeItem("htmlUrlCrearteZh");
    localStorage.removeItem("htmlUrlCrearteUs");
  },
};
</script>

<style lang="scss" scoped>
@import "../index.scss";

.el_form_item_footer {
  display: flex;
  justify-content: center;
  ::v-deep .el-form-item__content {
    margin-left: 0 !important;
  }
}
</style>

<style>
.el-popper,
.el-message,
.el-notification {
  z-index: 1111111 !important;
}
</style>
